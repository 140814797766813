.main-container {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/logo/bg-6.png');
  background-position: center;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  div {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 440px;
    width: 440px;
    padding: 44px;
    margin-bottom: 28px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.466);
    min-width: 320px;
    min-height: 338px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    // h1 {
    //   text-align: left;
    //   font-family: initial;
    //   font: 300 55px/110px Montserrat;
    //   letter-spacing: 0px;
    //   color: #ffffff;
    //   opacity: 1;
    //   line-height: 30px;
    // }
    h1 {
      text-align: left;
      font: 300 55px/30px Montserrat, sans-serif; /* Consolidated font properties */
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
  }
  
    // p {
    //   text-align: left;
    //   font-family: initial;
    //   font: 300 29px/58px Montserrat;
    //   letter-spacing: 0px;
    //   color: #ffffff;
    //   opacity: 1;
    // }
    p {
      text-align: left;
      font: 300 29px/58px Montserrat, sans-serif; /* Consolidated font properties */
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
  }
  
    section {
      background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
    }
  }
}

// .main-container {
//   width: 100vw;
//   min-height: 100vh;
//   background-color: #1a4ab9;

//   //background-image: url(https://aadcdn.msauthimages.net/dbd5a2dd-f17gu1gghnimsbbitvmatipfp00sw-gvbxi7gx6jqgy/logintenantbranding/0/illustration?ts=637702664829191791);
//   background-position: top center;
//   background-position-x: center;
//   background-position-y: top;
//   background-repeat: no-repeat;
//   background-repeat-x: no-repeat;
//   background-repeat-y: no-repeat;
//   background-size: cover;
//   display: flex;
//   align-items: center;
//   div {
//     margin-left: auto;
//     margin-right: auto;
//     position: relative;
//     max-width: 440px;
//     width: 440px;
//     padding: 44px;
//     margin-bottom: 28px;
//     background-color: #fff;
//     -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
//     -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
//     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.466);
//     min-width: 320px;
//     min-height: 338px;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     h1 {
//       text-align: left;
//       font-family: initial;
//       font: 300 55px/110px Montserrat;
//       letter-spacing: 0px;
//       color: #ffffff;
//       opacity: 1;
//       line-height: 30px;
//     }
//     p {
//       text-align: left;
//       font-family: initial;
//       font: 300 29px/58px Montserrat;
//       letter-spacing: 0px;
//       color: #ffffff;
//       opacity: 1;
//     }
//     section {
//       background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
//     }
//   }
// }
.bsk-container {
  margin-top: 15px;
  button {
    display: flex;
    gap: 12px;
    font-size: 15px;
    align-items: center;
    background: #0068b8;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    padding: 12px;
    color: #fff;
    margin-top: 25px;
    border: none;
    border-radius: 6px;
  }
  object {
    width: 25px;
    height: 25px;
  }
}
