@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
}

/* .table-responsive {
    max-height: 70vh;
    min-height: 70vh !important;
    height: 70vh !important;
} */

.custom-button-do {
    background-color: rgb(73, 151, 73);
    border-color: #fff;
    margin-top: 10px;
}

.commonMainDiv{
    padding: 8px 0px 0px 8px;
    width: 100%;
}
.commonMainDiv .commonHeadingTypo{
    margin-bottom: 0px;
    padding: 8px;
    font-size: 28px;
    line-height: 25px;
    color: rgb(32, 32, 32);
    overflow: hidden;
}

.commonMainDiv .secondDiv{
    font-size: 11px;
    display: block;
    color: rgba(32, 32, 32, 0.6);
    padding: 0px 8px 8px;
}

.whiteBg {
    background-color: #fff;
}
.commonBorder {
    border: 2px solid rgb(228, 228, 228);
    border-radius: 0px;
}
.commonInputLabel{
    margin: 0px;
    padding: 2px 0px;
    font-size: 11px;
    line-height: 18px;
    text-align: left;
}
.commonDropdownSelect{
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}
.p-b-10{
    padding-bottom: 10px;
}
.pb-5{
    padding-bottom: 50px;
}
.commonBorderBottom{
    border-bottom: 1px solid rgb(228, 228, 228);
}
.react-select-box__placeholder{
    text-align: left;
    font-size: 10px !important;
    line-height: 16px;
    color: rgb(33, 33, 33);
}
.react-select-box__indicator-separator{
    display: none;
}
.react-select-box__control{
    height: 22px;
    min-height: initial !important;
    border: none !important;
}
.react-select-box__input-container{
    margin: 0px !important;
    padding: 0px !important;
    max-height: 22px;
}
.react-select-box__input{
    max-height: 100%;
}
.react-select-box__value-container{
    height: 100%;
}
.react-select-box__indicator.react-select-box__dropdown-indicator{
    padding: 0px;
}
.commonText{
    font-size: 13px;
    line-height: 20px;
}
.ml-5{
    margin-left: 5px;
}

.modal-container {
    background-color: white;
    width: 100%;
    margin-top: 40px;
  }

  .modal {
    width: 500px;
    height: 400px;
    position: relative;
  }
  .modal_div{
    text-align: right;
    cursor:pointer;
    padding: 3px;
  }

  .py-30{
    padding-top: 30px;
    padding-bottom: 30px 
  }
  .customPieChart .recharts-default-legend{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .customPieChart .recharts-legend-item-text{
    font-size: 12px;
    line-height: 16px;
  }
  .customPieChart .recharts-surface .recharts-legend-icon{
    height: 14px;
  }
  .runHistoryTable td{
    font-size: 14px;
    line-height: 18px;
  }
  .commonPaginationText span{
    display: inline-block;
  }
  .commonPaginationText *{
    font-size: 14px;
    line-height: 18px;
}