.font-size {
    font-size: larger;
}

// thead,
// tbody tr {
//   width: 100%;
//   table-layout: fixed;
// }
// #root > div.row > div > div > div > div > div > div > div:nth-child(3) > div.table-responsive {
//   height: 450px;
// }

// #root > div.row > div > div > div > div > div > div > div:nth-child(3) > div:nth-child(1) > div {
//   height: 450px;
// }

#root
    > div.row
    > div
    > div
    > div
    > div
    > div
    > div
    > div
    > div:nth-child(3)
    > div.my-4
    > div {
    height: 500px;
}

// thead {
//     position: sticky;
//     top: 0px;
// }

// .global {
//   height: 450px;
//   overflow-x: auto;
//   overflow-y: auto;
// }

.table-cell-value-center {
    text-align: center;
}

#root > div > div > div > div > h2 > button {
    height: 120px;
}

.title {
    font-weight: bold;
    font-size: 22px;
}

.head1,
.head2,
.head3,
.head4 {
    font-size: 14px;
    line-height: 30px;
}

.body1,
.body2,
.body3,
.body4 {
    font-weight: bold;
    font-size: 16px;
}

.accordion-button:not(.collapsed) {
    background-color: #ffffff !important;
    color: #000000 !important;
}

td {
    vertical-align: middle;
}

.table-responsive {
    overflow-x: hidden !important;
}
.lastelement {
    text-align: center;
}
.display-linebreak {
    white-space: pre-line;
}
.table-main {
    border-bottom: 1px solid rgb(190, 189, 189);
}
.actions-styles{
    cursor: pointer;
}