.knobs,
.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button {
    position: relative;
    width: 74px;
    height: 26px;
    overflow: hidden;
}

.button.r,
.button.r .layer {
    border-radius: 100px;
}

.button.b2 {
    border-radius: 2px;
}

.checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

/* Button 10 */
#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
    position: absolute;
    width: 20px;
    height: 10px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
}

#button-10 .knobs:before {
    content: '';
    left: 4px;
    background-color: #03a9f4;
}

#button-10 .knobs:after {
    content: 'DESC';
    right: 4px;
    color: #4e4e4e;
}

#button-10 .knobs span {
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
}

#button-10 .checkbox:checked + .knobs span {
    color: #4e4e4e;
}

#button-10 .checkbox:checked + .knobs:before {
    left: 42px;
    background-color: #f44336;
}

#button-10 .checkbox:checked + .knobs:after {
    color: #fff;
}

#button-10 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
}

.sort-icon {
    margin-right: -13px;
}




.toggle-but {
    margin-top: 10px;
    margin-bottom: 10px;
}

  .btn-tog{
    border: 1px solid #0C6DFD;
    display: inline-block;
    padding: 3px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
  }
  
  input[type="radio"].toggle {
    display: none;
    & + label{
        cursor: pointer;
        min-width: 44px;
        &:after{
            background: #0C6DFD;
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after{
            left: 100%
        }
    }
    &.toggle-right + label{
        margin-left: -.1px;
        &:after{
            left: -100%;
        }
    }
    &:checked + label {
        cursor: default;
        color: #fff;
        transition: color 200ms;
        &:after{
            left: 0;
        }
    }
  }
