.font-size {
    font-size: larger;
}

// thead,
// tbody tr {
//   width: 100%;
//   table-layout: fixed;
// }
// #root > div.row > div > div > div > div > div > div > div:nth-child(3) > div.table-responsive {
//   height: 450px;
// }

// #root > div.row > div > div > div > div > div > div > div:nth-child(3) > div:nth-child(1) > div {
//   height: 450px;
// }

// #root
//     > div.row
//     > div
//     > div
//     > div
//     > div
//     > div
//     > div
//     > div
//     > div:nth-child(3)
//     > div.my-4
//     > div {
//     height: 500px;
// }

thead {
    position: sticky;
    top: 0px;
}

// .global {
//   height: 450px;
//   overflow-x: auto;
//   overflow-y: auto;
// }

.table-cell-value-center {
    text-align: center;
}

// #root > div > div > div > div > h2 > button {
//     height: 120px;
// }

.title {
    font-weight: bold;
    font-size: 22px;
}

// .head1,
// .head2,
// .head3,
// .head4 {
//     font-size: 14px;
//     line-height: 30px;
// }

// .body1,
// .body2,
// .body3,
// .body4 {
//     font-weight: bold;
//     font-size: 16px;
// }

.accordion-button:not(.collapsed) {
    background-color: #ffffff !important;
    color: #000000 !important;
}

td {
    vertical-align: middle;
}
.reportLogTable thead tr{
    background-color: #212529;
    color: #fff;
}
.reportLogTable thead th{
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    font-size: 13px;
    line-height: 20px;
}
.reportLogTable tbody tr:nth-child(even){
    background: #dee2e6;
}
.reportLogTable tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
.reportLogTable th,
.reportLogTable td{
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
} 