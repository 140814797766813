.declin-btn {
        border: 1px solid black;
        height: 31px;
        margin-top: 10px;
}

.notificationTabs {
        border: none;
}

.navTabs {
        display: inline-flex;
        background: #FAFAFA;
        border: 1px solid #ececec;
        border-radius: 10px;
        margin: 0 auto;
        padding: 3px;
        width: 100%;
}

.navTabs .nav-item {
        display: inline;
        // margin-left: 3px;
        padding-right: 5px;
    width: 20%;
}

.navTabs .nav-item:last-child {
        padding-right: 0;
}

.notificationTabs {
        border: none !important;
}

.markBtn {
        color: #0011cf;
        font-size: 14px;
}

.navTabs .nav-item .nav-link.active {
        color: #0011cf;
        font-size: 13px;
        border-radius: 5px;
        border-color: transparent;
        -webkit-box-shadow: 0px 4px 10px rgba(177, 177, 177, 0.2);
        -moz-box-shadow: 0px 4px 10px rgba(177, 177, 177, 0.2);
        box-shadow: 0px 4px 10px rgba(177, 177, 177, 0.2);
}

.navTabs .nav-item .nav-link.active .badge {
        color: #0011cf;
        background: #e0eaff !important;
}

.navTabs .nav-item .nav-link .badge {
        color: #666666;
        background: #e4e4e7 !important;
}

.navTabs .nav-item .nav-link {
        color: #666666;
        font-size: 13px;
        font-weight: 500;
        padding: 3px 5px;
    text-align: center;
}

.list-group-item span {
        font-size: 13px;
}

span.badge {
        margin-left: 3px;
    }